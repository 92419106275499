import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LinkIcon, ExclamationIcon } from '@heroicons/react/outline'

export default function Modal(props) {
  const [open, setOpen] = useState(false)

  React.useEffect(() => {
    if (props.open) {
      console.log('running')
      setOpen(true)
    }
  }, [props.open])

  const closeWithSuccess = async success => {
    setOpen(false)
    await new Promise(resolve => setTimeout(resolve, 300))
    props.closedModalWithSuccess(success)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => closeWithSuccess(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                {props.desktop ? (
                  <>
                    <div>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-orange-100">
                        <ExclamationIcon
                          className="h-8 w-8 text-orange-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="text-center">
                        <Dialog.Title as="h3" className="text-gray-900 mt-4">
                          you're on desktop
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-m text-gray-700">
                            you can't post stories from here. open this link
                            from your phone and try again ✨
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-600 font-semibold text-white hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:text-sm"
                        onClick={e => {
                          e.preventDefault()
                          closeWithSuccess(false)
                        }}>
                        go back
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-blue-100">
                        <LinkIcon
                          className="h-8 w-8 text-blue-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="text-center">
                        <Dialog.Title as="h3" className="text-gray-900 mt-4">
                          we've copied a link to your clipboard
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-m text-gray-700">
                            create your ig story (put whatever you want!) – but
                            make sure add a link sticker.
                            <br />
                            we've already copied the link, so you just have to
                            paste it ✨
                            <br />
                            make sure to tag{' '}
                            <span className={'font-semibold'}>@umber</span> so
                            we can repost it too 👀
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={e => {
                          e.preventDefault()
                          closeWithSuccess(true)
                        }}>
                        let's go
                      </button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
